import React from "react"
import TheaterVideo from "@components/Theater/TheaterVideo"
import { Button } from "../components/Button"

import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { CallToAction } from "../components/CTA"
import { Heading } from "../components/Hero"
import { Text, Image } from "../components/Core"
import { Columns, Section } from "../components/Containers"

class iCatPage extends React.Component {
  render() {
    const post = this.props.data.allUniquePagesJson.nodes[0]
    return (
      <Layout pageTitle="i-cat-page" language={this.props.pageContext.language}>
        <SEO
          title={post.metaTitle}
          description={post.metaDescription}
          pathname={this.props.location.pathname}
          lang={this.props.pageContext.language}
        />

        {!post.imageId ? (
          <Heading heading={post.heading} />
        ) : (
          <div className="nav-spacer">
            <Image useAR publicId={post.imageId} />
            <Section zeroBottom smallSpacing className="i-cat-page__hero">
              <Columns sideColumnsSize={3}>
                <div className="column">
                  <Text
                    className="mt-0  px-40--mobile"
                    as="h1"
                    text={post.heading}
                  />
                </div>
                {post.youtube && (
                  <>
                    <div className="column is-2"></div>
                    <div className="column is-narrow">
                      <TheaterVideo
                        language={this.props.pageContext.language}
                        videoUrl={`https://www.youtube.com/watch?v=${post.youtube}`}
                        controls
                        playing
                        hasWatchVideo
                        buttonClass="contained mt-0 mx-auto--mobile"
                      />
                    </div>
                  </>
                )}
              </Columns>
            </Section>
          </div>
        )}

        {post.bodySections.map((sect, i) => {
          if (sect.type === "text") {
            return (
              <Section key={i} smallSpacing>
                <Columns sideColumnsSize={3}>
                  <div className="column px-40--mobile">
                    <Text useStringReplace text={sect.string} />
                  </div>
                </Columns>
              </Section>
            )
          }

          if (sect.type === "video") {
            return (
              <Section key={i} smallSpacing>
                <Columns sideColumnsSize={3}>
                  <div className="column">
                    <TheaterVideo
                      buttonClass="contained"
                      videoUrl={`https://www.youtube.com/watch?v=${sect.youtube}`}
                      controls
                      playing
                      overVideo
                      language={this.props.pageContext.language}>
                      <Image publicId={sect.image} />
                    </TheaterVideo>
                  </div>
                </Columns>
              </Section>
            )
          }
        })}

        <CallToAction
          colorBack
          heading={post.getStarted.heading}
          blurb={post.getStarted.blurb}
          buttons={post.getStarted.buttons}
        />
      </Layout>
    )
  }
}

export const pageQuery = graphql`
  query threeDImagingPage($title: String!) {
    allUniquePagesJson(filter: { title: { eq: $title } }) {
      nodes {
        title
        heading
        metaTitle
        metaDescription
        youtube
        imageId
        bodySections {
          type
          string
          # youtube
          # image
        }
        getStarted {
          blurb
          buttonText
          heading
          href
          buttons {
            button {
              appearance
              destination
              href
              buttonText
            }
          }
        }
      }
    }
  }
`

export default iCatPage
